<template>
  <div id="contact">
    <b-container class="base-container-x contact-header-box" style="min-height:500px;background-color:#fff">
      <div v-if="loading == true" class="col-12">
        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
      </div>
      <div class="banner-inner contact">
        <div v-if="loading == false" class="slideshow_caption">
          <div class="slideshow_inner_caption" style="position: absolute;">
            <div class="slideshow_align_caption">
              <h1 class="section-header-title">{{ $t('Contact') }}</h1>
              <h3 class="article-content">{{ $t('If you have any questions or need assistance, feel free to contact us.!') }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loading == true" class="col-12 mt-4">
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
        <br/>
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
      </div>
      <div v-if="loading == false" class="col-12 mb-5 pt-4">
        <div class="mt-5">
        </div>
      </div>
      <div class="col-12">
        <b-row>
          <b-col sm="12" md="5" lg="5" class="pb-3">
            <img src="../../assets/images/contact/contact.jpg" width="100%"/>
            <h5>{{ $t('Our Location') }}</h5>
            <p><strong>{{ $t('World Yoga Alliance') }}:</strong> {{ $t('Shanti Marg, Haripur Kalan, Haridwar, Raiwala, Dehradun, Uttarakhand 249205, India')}}</p>
          </b-col>
          <b-col sm="12" md="7" lg="7">
            <h4 class="">{{ $t('Contact Us') }}</h4>
            <table class="table table-bordered">
            <tbody>
            <tr>
            <td style="text-align: center;" width="94">📧</td>
            <td ><strong>{{ $t('Official Email') }}</strong></td>
            <td >contact@wyayoga.org</td>
            </tr>
            <tr>
            <td></td>
            <td ><strong>{{ $t('Email for general information / queries / service assistance') }}</strong></td>
            <td>wyayoga@gmail.com</td>
            </tr>
            <tr>
            <td style="text-align: center;" width="94">💬</td>
            <td><strong>WhatsApp / Viber</strong></td>
            <td>+66-61-939-6595</td>
            </tr>
            <tr>
            <td style="text-align: center;" width="94">☎️</td>
            <td><strong>{{ $t('Phone') }}</strong></td>
            <td>+919625793618</td>
            </tr>
            </tbody>
            </table>
            <p><strong>{{ $t('Office Hours') }}:</strong><span> {{ $t('MON-FRI , 9:00-17:00') }}</span></p>
          </b-col>
        </b-row>
         <b-row class="mt-4 mb-4">
          <b-col sm="12" md="5" lg="5">
            <h5 class="font-weight-600">{{ $t('Get in touch with WYA') }}</h5>
            <label class="font-weight-500 d-none">{{ $t('FOLLOW US!') }}</label>
            <div class="fb-page"
              data-href="https://www.facebook.com/WYAYOGA"
              data-tabs=""
              data-width=""
              data-height=""
              data-small-header="false"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="false">
              <blockquote
                cite="https://www.facebook.com/WYAYOGA"
                class="fb-xfbml-parse-ignore">
                <a href="https://www.facebook.com/WYAYOGA" target="_blank">{{ $t('World Yoga Alliance') }}</a></blockquote></div>
            <div class="mt-5 mb-5">
              <label class="font-weight-500"> {{ $t('WYA SOCIAL MEDIA') }} </label>
              <b-col class="d-flex row pt-2 contact-social text-center">
                <a :href="instagramUrl" target="_blank" class="header-text-icon mx-1"><font-awesome-icon :icon="['fab', 'instagram']" size=""/></a>
                <a :href="twitterUrl" target="_blank" class="header-text-icon mx-1"><font-awesome-icon :icon="['fab', 'twitter']" size=""/></a>
                <a :href="youtubeUrl" target="_blank" class="header-text-icon mx-1"><font-awesome-icon :icon="['fab', 'youtube']" size=""/></a>
                <a :href="tumblrUrl" target="_blank" class="header-text-icon mx-1"><font-awesome-icon :icon="['fab', 'tumblr']" size=""/></a>
              </b-col>
            </div>
          </b-col>
          <b-col sm="12" md="7" lg="7">
            <h5>{{ $t('Contact') }}</h5>
            <b-form @submit.prevent="onSubmit">
              <b-form-group :label="$t('Name') + '*'" >
                <b-form-input id="input-name" type="text" required v-model="inputForm.name"></b-form-input>
              </b-form-group>
              <b-form-group :label="$t('Email') + '*'">
                <b-form-input id="input-email" type="email" required v-model="inputForm.email"></b-form-input>
              </b-form-group>
              <b-form-group :label="$t('Subject contact') + '*'">
                <b-form-input id="input-subject" type="text" required v-model="inputForm.subject"></b-form-input>
              </b-form-group>
              <b-form-group :label="$t('Message') + '*'">
              <b-form-textarea id="input-message" type="text" rows="6" v-model="inputForm.message"></b-form-textarea>
              </b-form-group>
              <vue-recaptcha
                :sitekey="recaptchaKey"
                @verify="onVerify"/>
              <b-col cols="12" class=" text-center text-md-left">
              <b-button pill  type="submit" :disabled="isLoading || !isNotRobot" class="mr-3 mt-2 px-5 my-4 font-weight-bold btn-primary-dark btn-lg">{{ $t('Submit') }}</b-button>
              </b-col>
            </b-form>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import Api from '../../api/api'
import VueRecaptcha from 'vue-recaptcha'
export default {
  name: 'Contact',
  title: ' | Contact',
  components: {
    VueRecaptcha
  },
  data () {
    return {
      twitterUrl: process.env.VUE_APP_TWITTER_URL,
      facebookUrl: process.env.VUE_APP_FACEBOOK_URL,
      instagramUrl: process.env.VUE_APP_INSTAGRAM_URL,
      youtubeUrl: process.env.VUE_APP_YOUTUBE_URL,
      tumblrUrl: process.env.VUE_APP_TUMBLR_URL,
      recaptchaKey: process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY,
      loading: true,
      isLoading: false,
      isNotRobot: false,
      inputForm: {
        name: '',
        email: '',
        subject: '',
        message: ''
      }
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.loading = false
  },
  methods: {
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    },
    onVerify (response) {
      if (response) {
        this.isNotRobot = true
      }
    },
    onSubmit () {
      if (this.isNotRobot) {
        this.isLoading = true
        Api.addContact(this.inputForm)
          .then((response) => {
            this.isLoading = false
            this.resetForm()
            this.$notify({
              group: 'success',
              text: this.$t('successfully')
            })
          })
      }
    },
    resetForm () {
      this.inputForm = {
        name: '',
        email: '',
        subject: '',
        message: ''
      }
      this.isNotRobot = false
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>

<style scoped>
  #contact {
  /* Lock Text */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
</style>
